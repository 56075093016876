import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    constructor() {
    }

    defaultMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Pudding Street Demo Website.',
            type: 'dropDown',
            icon: 'i-Bar-Chart',
            sub: [
                { icon: 'i-Clock-3', name: 'Version 1', state: '/dashboard/v1', type: 'link' },
                { icon: 'i-Clock-4', name: 'Version 2', state: '/dashboard/v2', type: 'link' },
                { icon: 'i-Over-Time', name: 'Version 3', state: '/dashboard/v3', type: 'link' },
                { icon: 'i-Clock', name: 'Version 4', state: '/dashboard/v4', type: 'link' },
            ]
        },
        {
            name: 'UI kits',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { icon: 'i-Bell', name: 'Alerts', state: '/uikits/alerts', type: 'link' },
                { icon: 'i-Split-Horizontal-2-Window', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
                { icon: 'i-Medal-2', name: 'Badges', state: '/uikits/badges', type: 'link' },
                {
                    icon: 'i-Arrow-Right-in-Circle',
                    name: 'Buttons',
                    type: 'dropDown',
                    sub: [
                        { name: 'Bootstrap Buttons', state: '/uikits/buttons', type: 'link' },
                        { name: 'Loding Buttons', state: '/uikits/buttons-loading', type: 'link' }
                    ]
                },
                { icon: 'i-ID-Card', name: 'Cards', state: '/uikits/cards', type: 'link' },
                { icon: 'i-Line-Chart-2', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
                { icon: 'i-Credit-Card', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
                { icon: 'i-Full-Cart', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
                { icon: 'i-Duplicate-Window', name: 'Modals', state: '/uikits/modals', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Popover', state: '/uikits/popover', type: 'link' },
                { icon: 'i-Like', name: 'Rating', state: '/uikits/rating', type: 'link' },
                { icon: 'i-Loading-3', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
            ]
        },
        {
            name: 'Apps',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Computer-Secure',
            sub: [
                { icon: 'i-Add-File', name: 'Invoice Builder', state: '/invoice', type: 'link' },
                { icon: 'i-Email', name: 'Inbox', state: '/inbox', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Chat', state: '/chat', type: 'link' },
                { icon: 'i-Calendar', name: 'Calendar', state: '/calendar', type: 'link' },
            ]
        },
        {
            name: 'Forms',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Clipboard-File--Text',
            sub: [
                { icon: 'i-File-Clipboard-Text--Image', name: 'Basic components', state: '/forms/basic', type: 'link' },
                { icon: 'i-Split-Vertical', name: 'Form layouts', state: '/forms/layouts', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Input Group', state: '/forms/input-group', type: 'link' },
                { icon: 'i-File-Edit', name: 'Input Mask', state: '/forms/input-mask', type: 'link' },
                { icon: 'i-Tag-2', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
                { icon: 'i-Width-Window', name: 'Wizard', state: '/forms/wizard', type: 'link' },
                { icon: 'i-Crop-2', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
            ]
        },
        {
            name: 'Data Tables',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Horizontal-Text',
            sub: [
                { icon: 'i-File-Horizontal-Text', name: 'List', state: '/tables/list', type: 'link' },
                { icon: 'i-Full-View-Window', name: 'Fullscreen', state: '/tables/full', type: 'link' },
                { icon: 'i-Code-Window', name: 'Paging', state: '/tables/paging', type: 'link' },
                { icon: 'i-Filter-2', name: 'Filter', state: '/tables/filter', type: 'link' },
            ]
        },
        {
            name: 'Sessions',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Administrator',
            sub: [
                { icon: 'i-Add-User', name: 'Sign up', state: '/sessions/signup', type: 'link' },
                { icon: 'i-Checked-User', name: 'Sign in', state: '/sessions/signin', type: 'link' },
                { icon: 'i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
            ]
        },
        {
            name: 'Pages',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Windows-2',
            sub: [
                { icon: 'i-Male', name: 'User Profile', state: '/pages/profile', type: 'link' }
            ]
        },
        {
            name: 'Icons',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Cloud-Sun',
            state: '/icons/iconsmind'
        },
        {
            name: 'Others',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Double-Tap',
            sub: [
                { icon: 'i-Error-404-Window', name: 'Not found', state: '/others/404', type: 'link' }
            ]
        },
        {
            name: 'Doc',
            type: 'extLink',
            tooltip: 'Documentation',
            icon: 'i-Safe-Box1',
            state: 'http://demos.ui-lib.com/gull-doc'
        }
    ];

    adminMenuBackup: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Pudding Street Demo Website.',
            type: 'dropDown',
            icon: 'i-Bar-Chart',
            sub: [
                { icon: 'i-Clock-3', name: 'admin d1', state: '/dashboard/admin', type: 'link' },
                { icon: 'i-Clock-4', name: 'admin d2', state: '/dashboard/recruiter', type: 'link' },
                { icon: 'i-Over-Time', name: 'admin d3', state: '/dashboard/client', type: 'link' },
                { icon: 'i-Clock', name: 'admin d4', state: '/dashboard/candidate', type: 'link' },
            ]
        },
        {
            name: 'Apps',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Computer-Secure',
            sub: [
                { icon: 'i-Add-File', name: 'Invoice Builder', state: '/invoice', type: 'link' },
                { icon: 'i-Email', name: 'Inbox', state: '/inbox', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Chat', state: '/chat', type: 'link' },
                { icon: 'i-Calendar', name: 'Calendar', state: '/calendar', type: 'link' },
            ]
        },
        {
            name: 'Forms',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Clipboard-File--Text',
            sub: [
                { icon: 'i-File-Clipboard-Text--Image', name: 'Basic components', state: '/forms/basic', type: 'link' },
                { icon: 'i-Split-Vertical', name: 'Form layouts', state: '/forms/layouts', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Input Group', state: '/forms/input-group', type: 'link' },
                { icon: 'i-File-Edit', name: 'Input Mask', state: '/forms/input-mask', type: 'link' },
                { icon: 'i-Tag-2', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
                { icon: 'i-Width-Window', name: 'Wizard', state: '/forms/wizard', type: 'link' },
                { icon: 'i-Crop-2', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
            ]
        },
        {
            name: 'Data Tables',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Horizontal-Text',
            sub: [
                { icon: 'i-File-Horizontal-Text', name: 'List', state: '/tables/list', type: 'link' },
                { icon: 'i-Full-View-Window', name: 'Fullscreen', state: '/tables/full', type: 'link' },
                { icon: 'i-Code-Window', name: 'Paging', state: '/tables/paging', type: 'link' },
                { icon: 'i-Filter-2', name: 'Filter', state: '/tables/filter', type: 'link' },
            ]
        },
        {
            name: 'Sessions',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Administrator',
            sub: [
                { icon: 'i-Add-User', name: 'Sign up', state: '/sessions/signup', type: 'link' },
                { icon: 'i-Checked-User', name: 'Sign in', state: '/sessions/signin', type: 'link' },
                { icon: 'i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
            ]
        },
        {
            name: 'Pages',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Windows-2',
            sub: [
                { icon: 'i-Male', name: 'User Profile', state: '/pages/profile', type: 'link' }
            ]
        },
        {
            name: 'Icons',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Cloud-Sun',
            state: '/icons/iconsmind'
        },
        {
            name: 'Others',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Double-Tap',
            sub: [
                { icon: 'i-Error-404-Window', name: 'Not found', state: '/others/404', type: 'link' }
            ]
        },
        {
            name: 'Doc',
            type: 'extLink',
            tooltip: 'Documentation',
            icon: 'i-Safe-Box1',
            state: 'http://demos.ui-lib.com/gull-doc'
        }
    ];

    adminMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Pudding Street Demo Website.',
            type: 'link',
            state:'/admin/dashboard/admin',
            icon: 'i-Bar-Chart'
        },
        {
            name: 'Companies',
            description: 'Manage Companies',
            type: 'link',
            state:'/admin/company/list',
            icon: 'i-Computer-Secure'
        },
        {
            name: 'Moderators',
            description: 'Manage Moderators',
            type: 'link',
            state:'/admin/moderators/list',
            icon: 'i-Windows-2'
        },
        {
            name: 'CompanyAdmins',
            description: 'Manage Company Admins',
            type: 'link',
            state:'/admin/companyadmin/list',
            icon: 'i-Windows-2'
        }
    ];

    moderatorMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Moderator Dashboard.',
            type: 'link',
            state:'/moderator/dashboard/moderator',
            icon: 'i-Bar-Chart'
        },
        {
            name: 'Company',
            description: 'Manage Companies',
            type: 'link',
            state:'/moderator/company/list',
            icon: 'i-Administrator'
        },
        {
            name: 'Investor',
            description: 'Manage Investors',
            type: 'link',
            state:'/cadmin/investor/list',
            icon: 'i-File-Clipboard-File--Text'
        },
        {
            name: 'Investments',
            description: 'Manage Investments',
            type: 'link',
            state:'/cadmin/investment/list',
            icon: 'i-Computer-Secure'
        },
        {
            name: 'Documents',
            description: 'Manage Documents',
            type: 'link',
            state:'/cadmin/document/list',
            icon: 'i-Windows-2'
        }
    ];

    cadminMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Company Admin Dashboard.',
            type: 'link',
            state:'/cadmin/dashboard/cadmin',
            icon: 'i-Bar-Chart'
        },
        {
            name: 'Projects',
            description: 'Manage Projects',
            type: 'link',
            state:'/cadmin/project/list',
            icon: 'i-Administrator'
        },
        {
            name: 'Investor',
            description: 'Manage Investors',
            type: 'link',
            state:'/cadmin/investor/list',
            icon: 'i-File-Clipboard-File--Text'
        },
        {
            name: 'Investments',
            description: 'Manage Investments',
            type: 'link',
            state:'/cadmin/investment/list',
            icon: 'i-Computer-Secure'
        },
        {
            name: 'Documents',
            description: 'Manage Documents',
            type: 'link',
            state:'/cadmin/document/list',
            icon: 'i-Windows-2'
        }
    ];

    cmoderatorMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Company Moderator Dashboard.',
            type: 'link',
            state:'/cmoderator/dashboard/cmoderator',
            icon: 'i-Bar-Chart'
        },
        {
            name: 'Projects',
            description: 'Manage Projects',
            type: 'link',
            state:'/cmoderator/project/list',
            icon: 'i-Administrator'
        },
        {
            name: 'Investor',
            description: 'Manage Investors',
            type: 'link',
            state:'/cmoderator/investor/list',
            icon: 'i-File-Clipboard-File--Text'
        },
        {
            name: 'Investments',
            description: 'Manage Investments',
            type: 'link',
            state:'/cmoderator/investment/list',
            icon: 'i-Computer-Secure'
        },
        {
            name: 'Documents',
            description: 'Manage Documents',
            type: 'link',
            state:'/cmoderator/document/list',
            icon: 'i-Windows-2'
        }
    ];

    investorMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Investor Dashboard.',
            type: 'link',
            state:'/investor/dashboard/investor',
            icon: 'i-Bar-Chart'
        },
        {
            name: 'Projects',
            description: 'Manage Projects',
            type: 'link',
            state:'/investor/project/list',
            icon: 'i-Administrator'
        },
        {
            name: 'Investments',
            description: 'Manage Investments',
            type: 'link',
            state:'/investor/investment/list',
            icon: 'i-Computer-Secure'
        },
        {
            name: 'Documents',
            description: 'Manage Documents',
            type: 'link',
            state:'/investor/document/list',
            icon: 'i-File-Clipboard-File--Text'
        },
        {
            name: 'Gallery',
            description: 'Gallery',
            type: 'link',
            state:'/investor/gallery/annualevent2021',
            icon: 'i-File-Clipboard-File--Text'
        }
    ];

    clientMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Pudding Street Demo Website.',
            type: 'link',
            state:'/client/dashboard/client',
            icon: 'i-Bar-Chart'
        },
        {   
            name: 'Candidates',
            description: 'Pudding Street Demo Website.',
            type: 'link',
            state:'/client/candidate/list',
            icon: 'i-Administrator'
        },
        {
            name: 'Subscribe to more',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            state:'/client/subscription/subscribe',
            icon: 'i-Computer-Secure'
        }
    ];

    recruiterMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Pudding Street Demo Website.',
            type: 'link',
            state:'/recruiter/dashboard/recruiter',
            icon: 'i-Bar-Chart'
        },
        {
            name: 'Candidates',
            description: 'Add candidates',
            type: 'link',
            state:'/recruiter/candidate/list',
            icon: 'i-Administrator'
        },
        {
            name: 'Talent Pipeline',
            description: 'View/Edit talent pipeline',
            type: 'link',
            state:'/recruiter/dashboard/recruiter',
            icon: 'i-Computer-Secure'
        },
        {
            name: 'Jobs',
            description: 'Recruiter can add jobs',
            type: 'link',
            state:'/recruiter/job/list',
            icon: 'i-File-Clipboard-File--Text'
        },
        {
            name: 'Action Log',
            description: 'Recuiter Action Log',
            type: 'link',
            state:'/recruiter/actionlog/list',
            icon: 'i-Windows-2'
        }
    ];

    candidateMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Pudding Street Demo Website.',
            type: 'dropDown',
            icon: 'i-Bar-Chart',
            sub: [
                { icon: 'i-Clock-3', name: 'candidate d1', state: '/dashboard/admin', type: 'link' },
                { icon: 'i-Clock-4', name: 'candidate d2', state: '/dashboard/candidate', type: 'link' },
                { icon: 'i-Over-Time', name: 'candidate d3', state: '/dashboard/client', type: 'link' },
                { icon: 'i-Clock', name: 'candidate d4', state: '/dashboard/recruiter', type: 'link' },
            ]
        },
        {
            name: 'UI kits',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { icon: 'i-Bell', name: 'Alerts', state: '/uikits/alerts', type: 'link' },
                { icon: 'i-Split-Horizontal-2-Window', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
                { icon: 'i-Medal-2', name: 'Badges', state: '/uikits/badges', type: 'link' },
                {
                    icon: 'i-Arrow-Right-in-Circle',
                    name: 'Buttons',
                    type: 'dropDown',
                    sub: [
                        { name: 'Bootstrap Buttons', state: '/uikits/buttons', type: 'link' },
                        { name: 'Loding Buttons', state: '/uikits/buttons-loading', type: 'link' }
                    ]
                },
                { icon: 'i-ID-Card', name: 'Cards', state: '/uikits/cards', type: 'link' },
                { icon: 'i-Line-Chart-2', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
                { icon: 'i-Credit-Card', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
                { icon: 'i-Full-Cart', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
                { icon: 'i-Duplicate-Window', name: 'Modals', state: '/uikits/modals', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Popover', state: '/uikits/popover', type: 'link' },
                { icon: 'i-Like', name: 'Rating', state: '/uikits/rating', type: 'link' },
                { icon: 'i-Loading-3', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
            ]
        },
        {
            name: 'Apps',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Computer-Secure',
            sub: [
                { icon: 'i-Add-File', name: 'Invoice Builder', state: '/invoice', type: 'link' },
                { icon: 'i-Email', name: 'Inbox', state: '/inbox', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Chat', state: '/chat', type: 'link' },
                { icon: 'i-Calendar', name: 'Calendar', state: '/calendar', type: 'link' },
            ]
        },
        {
            name: 'Forms',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Clipboard-File--Text',
            sub: [
                { icon: 'i-File-Clipboard-Text--Image', name: 'Basic components', state: '/forms/basic', type: 'link' },
                { icon: 'i-Split-Vertical', name: 'Form layouts', state: '/forms/layouts', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Input Group', state: '/forms/input-group', type: 'link' },
                { icon: 'i-File-Edit', name: 'Input Mask', state: '/forms/input-mask', type: 'link' },
                { icon: 'i-Tag-2', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
                { icon: 'i-Width-Window', name: 'Wizard', state: '/forms/wizard', type: 'link' },
                { icon: 'i-Crop-2', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
            ]
        },
        {
            name: 'Data Tables',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Horizontal-Text',
            sub: [
                { icon: 'i-File-Horizontal-Text', name: 'List', state: '/tables/list', type: 'link' },
                { icon: 'i-Full-View-Window', name: 'Fullscreen', state: '/tables/full', type: 'link' },
                { icon: 'i-Code-Window', name: 'Paging', state: '/tables/paging', type: 'link' },
                { icon: 'i-Filter-2', name: 'Filter', state: '/tables/filter', type: 'link' },
            ]
        },
        {
            name: 'Sessions',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Administrator',
            sub: [
                { icon: 'i-Add-User', name: 'Sign up', state: '/sessions/signup', type: 'link' },
                { icon: 'i-Checked-User', name: 'Sign in', state: '/sessions/signin', type: 'link' },
                { icon: 'i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
            ]
        },
        {
            name: 'Pages',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Windows-2',
            sub: [
                { icon: 'i-Male', name: 'User Profile', state: '/pages/profile', type: 'link' }
            ]
        },
        {
            name: 'Icons',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Cloud-Sun',
            state: '/icons/iconsmind'
        },
        {
            name: 'Others',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Double-Tap',
            sub: [
                { icon: 'i-Error-404-Window', name: 'Not found', state: '/others/404', type: 'link' }
            ]
        },
        {
            name: 'Doc',
            type: 'extLink',
            tooltip: 'Documentation',
            icon: 'i-Safe-Box1',
            state: 'http://demos.ui-lib.com/gull-doc'
        }
    ];

    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    adminMenuItems = new BehaviorSubject<IMenuItem[]>(this.adminMenu);
    // navigation component has subscribed to this Observable
    adminMenuItems$ = this.adminMenuItems.asObservable();

    recruiterMenuItems = new BehaviorSubject<IMenuItem[]>(this.recruiterMenu);
    // navigation component has subscribed to this Observable
    recruiterMenuItems$ = this.recruiterMenuItems.asObservable();

    moderatorMenuItems = new BehaviorSubject<IMenuItem[]>(this.moderatorMenu);
    // navigation component has subscribed to this Observable
    moderatoradminMenuItems$ = this.moderatorMenuItems.asObservable();

    companyadminMenuItems = new BehaviorSubject<IMenuItem[]>(this.cadminMenu);
    // navigation component has subscribed to this Observable
    companyadminMenuItems$ = this.companyadminMenuItems.asObservable();

    companymoderatorMenuItems = new BehaviorSubject<IMenuItem[]>(this.cmoderatorMenu);
    // navigation component has subscribed to this Observable
    companymoderatorMenuItems$ = this.companymoderatorMenuItems.asObservable();

    investorMenuItems = new BehaviorSubject<IMenuItem[]>(this.investorMenu);
    // navigation component has subscribed to this Observable
    investorMenuItems$ = this.investorMenuItems.asObservable();

    clientMenuItems = new BehaviorSubject<IMenuItem[]>(this.clientMenu);
    // navigation component has subscribed to this Observable
    clientMenuItems$ = this.clientMenuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(userType: string) : Observable<any>
    //  {
    //   switch (userType) {
    //     case 'admin':
    //         this.menuItems = new BehaviorSubject<IMenuItem[]>(this.adminMenu);
    //         //this.menuItems.next(this.adminMenu);
    //         break;
    //     case 'recruiter':
    //         this.menuItems = new BehaviorSubject<IMenuItem[]>(this.recruiterMenu);  
    //         //this.menuItems.next(this.recruiterMenu);
    //         break;
    //     case 'client':
    //         this.menuItems = new BehaviorSubject<IMenuItem[]>(this.clientMenu);  
    //         //this.menuItems.next(this.clientMenu);
    //         break;
    //     case 'candidate':
    //         this.menuItems = new BehaviorSubject<IMenuItem[]>(this.candidateMenu);
    //         //this.menuItems.next(this.candidateMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    //   return this.menuItems;
    // }
}
