import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { AdminLayoutSidebarAdminComponent } from './shared/components/layouts/admin-layout-sidebar-admin/admin-layout-sidebar-admin.component';
import { CompanyModule } from './views/company/company.module';
  const adminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'uikits',
      loadChildren: () => import('./views/ui-kits/ui-kits.module').then(m => m.UiKitsModule)
    },
    {
      path: 'forms',
      loadChildren: () => import('./views/forms/forms.module').then(m => m.AppFormsModule)
    },
    {
      path: 'invoice',
      loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule)
    },
    {
      path: 'inbox',
      loadChildren: () => import('./views/inbox/inbox.module').then(m => m.InboxModule)
    },
    {
      path: 'calendar',
      loadChildren: () => import('./views/calendar/calendar.module').then(m => m.CalendarAppModule)
    },
    {
      path: 'chat',
      loadChildren: () => import('./views/chat/chat.module').then(m => m.ChatModule)
    },
    {
      path: 'tables',
      loadChildren: () => import('./views/data-tables/data-tables.module').then(m => m.DataTablesModule)
    },
    {
      path: 'pages',
      loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
    }
  ];

  const admRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'user',
      loadChildren: () => import('./views/competency/competency.module').then(m => m.CompetencyModule)
    },
    {
      path: 'company',
      loadChildren: () => import('./views/company/company.module').then(m => m.CompanyModule)
    },
    {
      path: 'companyadmin',
      loadChildren: () => import('./views/companyadmin/companyadmin.module').then(m => m.CompanyAdminModule)
    },
    {
      path: 'resetpassword',
      loadChildren: () => import('./views/candidate/candidate.module').then(m => m.CandidateModule)
    },
    {
      path: 'job',
      loadChildren: () => import('./views/job/job.module').then(m => m.JobModule)
    },
    {
      path: 'client',
      loadChildren: () => import('./views/client/client.module').then(m => m.ClientModule)
    },
    {
      path: 'uikits',
      loadChildren: () => import('./views/ui-kits/ui-kits.module').then(m => m.UiKitsModule)
    },
    {
      path: 'forms',
      loadChildren: () => import('./views/forms/forms.module').then(m => m.AppFormsModule)
    },
    {
      path: 'invoice',
      loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule)
    },
    {
      path: 'inbox',
      loadChildren: () => import('./views/inbox/inbox.module').then(m => m.InboxModule)
    },
    {
      path: 'calendar',
      loadChildren: () => import('./views/calendar/calendar.module').then(m => m.CalendarAppModule)
    },
    {
      path: 'chat',
      loadChildren: () => import('./views/chat/chat.module').then(m => m.ChatModule)
    },
    {
      path: 'tables',
      loadChildren: () => import('./views/data-tables/data-tables.module').then(m => m.DataTablesModule)
    },
    {
      path: 'pages',
      loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
    }
  ];

  const moderatorRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'usermgmt',
      loadChildren: () => import('./views/competency/competency.module').then(m => m.CompetencyModule)
    },
    {
      path: 'companymgmt',
      loadChildren: () => import('./views/recruiter/recruiter.module').then(m => m.RecruiterModule)
    },
    {
      path: 'reetpassword',
      loadChildren: () => import('./views/candidate/candidate.module').then(m => m.CandidateModule)
    }
  ];

  const companyAdminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'investor',
      loadChildren: () => import('./views/investor/investor.module').then(m => m.InvestorModule)
    },
    {
      path: 'investment',
      loadChildren: () => import('./views/investment/investment.module').then(m => m.InvestmentModule)
    },
    {
      path: 'project',
      loadChildren: () => import('./views/project/project.module').then(m => m.ProjectModule)
    },
    {
      path: 'document',
      loadChildren: () => import('./views/document/document.module').then(m => m.DocumentModule)
    },
    {
      path: 'account',
      loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
    }
  ];

  const companyModeratorRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'investor',
      loadChildren: () => import('./views/investor/investor.module').then(m => m.InvestorModule)
    },
    {
      path: 'investment',
      loadChildren: () => import('./views/investment/investment.module').then(m => m.InvestmentModule)
    },
    {
      path: 'project',
      loadChildren: () => import('./views/project/project.module').then(m => m.ProjectModule)
    },
    {
      path: 'document',
      loadChildren: () => import('./views/document/document.module').then(m => m.DocumentModule)
    },
    {
      path: 'account',
      loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
    }
  ];

  const investorRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'investment',
      loadChildren: () => import('./views/investment/investment.module').then(m => m.InvestmentModule)
    },
    {
      path: 'project',
      loadChildren: () => import('./views/project/project.module').then(m => m.ProjectModule)
    },
    {
      path: 'document',
      loadChildren: () => import('./views/document/document.module').then(m => m.DocumentModule)
    },
    {
      path: 'account',
      loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
    },
    {
      path: 'gallery',
      loadChildren: () => import('./views/gallery/gallery.module').then(m => m.GalleryModule)
    },
    {
      path: 'profile',
      loadChildren: () => import('./views/investor/investor.module').then(m => m.InvestorModule)
    }
  ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sessions/signin',
    pathMatch: 'full'
  },
  {
    path: 'admin1',
    redirectTo: 'dashboard/admin',
    pathMatch: 'full'
  },
  {
    path: 'recruiter1',
    redirectTo: 'dashboard/recruiter',
    pathMatch: 'full'
  },
  {
    path: 'client1',
    redirectTo: 'dashboard/client',
    pathMatch: 'full'
  },
  {
    path: 'candidate1',
    redirectTo: 'dashboard/candidate',
    pathMatch: 'full'
  },
  {
    path: 'admin',
    component: AdminLayoutSidebarAdminComponent,
    canActivate: [AuthGaurd],
    children: admRoutes
  },
  {
    path: 'moderator',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: moderatorRoutes
  },
  {
    path: 'cadmin',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: companyAdminRoutes
  },
  {
    path: 'cmoderator',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: companyModeratorRoutes
  },
  {
    path: 'investor',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: investorRoutes
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
